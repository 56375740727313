<template>
  <div class="dataTables_wrapper no-footer bg-white">
    <div class="pull-right">
      <button @click="exportData" class="btn btn-default">
        CSV出力
      </button>
    </div>
    <div class="table-responsive">
      <div class="dataTables_info p-0 app-text text-complete" style="margin-top: 0 !important;" role="status"
           aria-live="polite">
        全{{ meta.total }}件中 {{ meta.from }}件 〜 {{
          meta.to
        }}件 を表示
      </div>
      <table
        :class="[entries.length ? '' : 'table-empty-data', 'table dataTable app-table no-footer']">
        <thead>
        <tr>
          <th class="text-center" rowspan="2">NO.</th>
          <th class="text-center min-w-80" rowspan="2">予約コード</th>
          <th class="text-center min-w-80" rowspan="2">ホテル名</th>
          <th class="text-center min-w-80" rowspan="2">出発地</th>
          <th class="text-center min-w-100" rowspan="2">チェックイン日</th>
          <th class="text-center min-w-120" rowspan="2">チェックアウト日</th>
          <th class="text-center min-w-80" rowspan="2">泊数</th>
          <th class="text-center min-w-80" rowspan="2">宿泊代表者（漢字）</th>
          <th class="text-center min-w-100" rowspan="2">宿泊代表者<br>（フリガナ）</th>
          <th class="text-center min-w-80" rowspan="2">電話番号</th>
          <th class="text-center min-w-80" rowspan="2">住所</th>
          <th class="text-center min-w-80" colspan="7">人数</th>
          <th class="text-center min-w-80" rowspan="2">宿泊料金</th>
          <th class="text-center min-w-100" rowspan="2">タクシー料金</th>
          <th class="text-center min-w-100" rowspan="2">ドライバー料金</th>
          <th class="text-center min-w-100" rowspan="2">オプション料金</th>
          <th class="text-center min-w-120" rowspan="2">パック料金総合計</th>
          <th class="text-center min-w-80" rowspan="2">割引額</th>
          <th class="text-center min-w-120" rowspan="2">ドライバー宿泊先</th>
          <th class="text-center min-w-80" rowspan="2">備考</th>
          <th class="text-center min-w-80" rowspan="2">受付日</th>
          <th class="text-center min-w-80" rowspan="2">年齢</th>
          <th class="text-center min-w-80" rowspan="2">経路</th>
        </tr>
        <tr>
          <th class="text-center min-w-100">大人<br>（内訳不明）</th>
          <th class="text-center min-w-80">大人（男）</th>
          <th class="text-center min-w-80">大人（女）</th>
          <th class="text-center min-w-80">小学生</th>
          <th class="text-center min-w-80">未就学児</th>
          <th class="text-center min-w-80">乳児</th>
          <th class="text-center min-w-80">合計</th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="!entries.length">
          <td colspan="100">
                  <span v-if="loading">
                    {{ $t('common.loading') }}
                  </span>
            <span v-else>
                    {{ $t('common.list empty') }}
                  </span>
          </td>
        </tr>
        <tr v-for="entry in entries">
          <td class="app-table-p v-align-middle text-center">
            {{ entry.no }}
          </td>
          <td v-for="column in columns" :class="['v-align-middle', column.class]">
            <p :data-original-title="column.computed ? column.computed(entry) : _.get(entry, column.name)"
               class="app-table-p app-cell-tooltip">
              <span v-html="column.computed ? column.computed(entry) : _.get(entry, column.name)"></span>
            </p>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div class="d-flex justify-content-between">
        <div class="dataTables_paginate app-paginate-container">
          <AppPaginate
            v-if="parseInt(meta.last_page) > 1"
            ref="paginate"
            :page-count="parseInt(meta.last_page)"
            :page-range="5"
            :margin-pages="2"
            :click-handler="clickPagination"
            prev-text="<"
            next-text=">"
            breakViewClass="paginate-break"
            prev-class="paginate-button-prev"
            next-class="paginate-button-next"
            :force-page="parseInt(pagination.currentPage)"
            :hide-prev-next="true"
            :page-class="'paginate-button'">
          </AppPaginate>
        </div>
        <div class="d-flex justify-content-start padding-20 form-group dataTable_info_custom">
          <div class="m-r-5 m-t-5 perpage-label"> {{ $t('common.perpage') }}
          </div>
          <div class="form-group">
            <select class="form-control" @change="changePerPage"
                    v-model="pagination.currentPerPage">
              <option v-for="perpage in pagination.perPages" :value="perpage">
                {{ perpage }}
              </option>
            </select></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: [
    'filters'
  ],
  data() {
    return {
      entries: [],
      pagination: {
        currentPage: 1,
        currentPerPage: 10,
        perPages: [5, 10, 25, 50, 100, 250, 500],
      },
      meta: {},
      loading: true,
      columns: [
        {name: 'booking_code'},
        {name: 'hotel.name'},
        {name: 'start_point.name'},
        {name: 'checkin_date', class: 'text-center', computed: entry => this.formatDate(entry.checkin_date)},
        {name: 'checkout_date', class: 'text-center', computed: entry => this.formatDate(entry.checkout_date)},
        {name: 'hotel_night_count', class: 'text-center',},
        {name: 'c_name'},
        {name: 'c_name_kana'},
        {
          name: 'c_phone',
          computed: entry => {
            let cPhoneMobile = (entry.c_phone_mobile ? entry.c_phone_mobile + '（携帯）' : '')
            let cPhoneHome = (entry.c_phone_home ? entry.c_phone_home + '（自宅）' : '')
            if (cPhoneMobile.length || cPhoneHome.length) {
              return cPhoneMobile + ((cPhoneHome && cPhoneMobile) ? ' - ' : '') + cPhoneHome;
            }
          }
        },
        {name: 'c_address'},
        {name: 'c_num_note_1', class: 'text-center'},
        {name: 'c_num_note_2', class: 'text-center'},
        {name: 'c_num_note_3', class: 'text-center'},
        {name: 'c_num_note_4', class: 'text-center'},
        {name: 'c_num_4', class: 'text-center'},
        {name: 'c_num_5', class: 'text-center'},
        {name: 'c_num_total', class: 'text-center'},
        {name: 'c_price_hotel', class: 'text-right', computed: entry => this.formatNumber(entry.c_price_hotel)},
        {name: 'total_price_taxi', class: 'text-right', computed: entry => this.formatNumber(entry.total_price_taxi)},
        {name: 'c_price_driver', class: 'text-right', computed: entry => this.formatNumber(entry.c_price_driver)},
        {
          name: 'price_tourist_options',
          class: 'text-right',
          computed: entry => this.formatNumber(entry.price_tourist_options)
        },
        {
          name: 'total_price_package', class: 'text-right',
          computed: entry => this.formatNumber(entry.total_price_final - entry.total_price_discount)
        },
        {
          name: 'total_price_discount',
          class: 'text-right',
          computed: entry => this.formatNumber(entry.total_price_discount)
        },
        {name: 'driver_hotel.name'},
        {name: 'taxi_notes'},
        {name: 'booking_date', class: 'text-center', computed: entry => this.formatDate(entry.booking_date)},
        {name: 'c_age', class: 'text-center'},
        {
          name: 'receive_type', class: 'text-center', computed: entry => {
            if (entry.receive_type === 1) {
              return 'WEB';
            }
            if (entry.receive_type === 2) {
              return 'TEL';
            }
            return '';
          }
        },
      ],
    }
  },
  watch: {
    "$route.query": {
      handler: function (after, before) {
        if (!_.isEqual(after, before)) {
          this.getEntries()
        }
      },
      deep: true,
    }
  },
  mounted() {
    this.getEntries()
  },
  methods: {
    clickPagination(selectPage) {
      if (selectPage !== this.pagination.currentPage) {
        this.setCurrentPage(selectPage)
        this.getEntries()
      }
    },
    changePerPage() {
      this.setCurrentPage(1)
      this.getEntries()
    },
    setCurrentPage(page) {
      this.pagination.currentPage = page;
      if (this.$refs.paginate) {
        this.$refs.paginate.handlePageSelected(page);
      }
    },
    async getEntries() {
      const {currentPage, currentPerPage} = this.pagination
      let params = {
        page: currentPage,
        perPage: currentPerPage,
      }
      _.forEach(this.filters, (filter) => {
        if (filter.value && filter.value.toString().length) {
          params[`filters[${filter.name}][${filter.type}]`] = filter.value
        }
      })

      const res = await this.$request.get(this.$consts.API.CS.ANALYSIS_BY_BOOKING, {...params})
      if (!res.hasErrors()) {
        this.entries = res.data.data.map((entry, index) => {
          return {
            ...entry, no: (index + 1 + (this.pagination.currentPage - 1) * this.pagination.currentPerPage)
          }
        })
        this.meta = res.data.meta
        if (!this.meta.from) {
          this.meta.from = 0;
        }
        if (!this.meta.to) {
          this.meta.to = 0;
        }
      }
      if (this.pagination.currentPage > 1 && this.entries.length === 0) {
        this.setCurrentPage(1)
        this.getEntries()
      }
      this.$nextTick(() => {
        $.each($('.app-cell-tooltip'), (index, el) => {
          if (el.offsetWidth < el.scrollWidth) {
            $(el).tooltip({boundary: 'window'})
          }
        })
      });
      this.loading = false;
    },
    exportData() {
      var params = {}
      _.forEach(this.filters, (filter) => {
        if (filter.value && filter.value.toString().length) {
          params[`filters[${filter.name}][${filter.type}]`] = filter.value
        }
      })
      this.$request.get(this.$consts.API.CS.ANALYSIS_EXPORT_BOOKING, {...params}).then(res => {
        if (!res.hasErrors()) {
          this.downloadFromUrl(res.data.url)
        } else {
          this.$appNotice.error();
        }
      })
    },
    onShow() {
      setTimeout(() => {
        $.each($('.app-cell-tooltip'), (index, el) => {
          if (el.offsetWidth < el.scrollWidth) {
            $(el).tooltip({boundary: 'window'})
          }
        })
      }, 200)
    }
  }
};
</script>
