<template>
  <div class=" container-fluid container-fixed-lg bg-white app-table-container">
    <div class="card card-transparent">
      <div class="card-header search-container">
        <div class="search-content">
          <ValidationObserver v-slot="{ passes, valid, validated }">
            <form novalidate autocomplete="off" @submit.prevent="passes(searchHandle)">
              <div>
                <div class="d-flex justify-content-start col-md-12">
                  <div v-for="(filter, index) in filters" v-if="filter.uiType" class="col-md-6">
                    <template v-if="filter.uiType === 'datepicker_from'">
                      <div class="row">
                        <div class="col-md-5-5">
                          <AppDatePicker :rules="filter.rules ? filter.rules : ''"
                                         input-style="normal" :name="filter.name"
                                         :label="filter.label"
                                         :options="{
                                           format: 'yyyy/mm',
                                           startView: 'months',
                                           minViewMode: 'months'
                                         }"
                                         label-class="text-complete"
                                         v-model="filters[index].value"/>
                        </div>
                        <div class="col-md-1 p-0 m-0">
                          <div class="form-group app-form-group b-b-0">
                            <label class="input-label control-label p-0 p-r-5 visible-none">
                              none
                            </label>
                            <div class="form-group m-b-0 p-0 text-center" style="height: 50px; line-height: 50px">
                              ～
                            </div>
                          </div>
                        </div>
                        <div class="col-md-5-5">
                          <AppDatePicker
                            input-style="normal"
                            label="none"
                            :options="{
                               format: 'yyyy/mm',
                               startView: 'months',
                               minViewMode: 'months'
                             }"
                            :name="filters[index+1].name"
                            label-class=" visible-none"
                            v-model="filters[index+1].value"/>
                        </div>
                      </div>
                    </template>
                  </div>
                  <div style="margin-top: 34px">
                    <button @click="resetAllSearch"
                            class="btn btn-default m-r-10" type="button">
                      {{ $t('common.reset') }}
                    </button>
                    <button :disabled="!valid && validated" class="btn btn-default text-complete m-r-10" type="submit">
                      {{ $t('common.search') }}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
      <div class="card-body">
        <!-- Nav tabs -->
        <ul class="nav nav-tabs nav-tabs-fillup" data-init-reponsive-tabs="dropdownfx">
          <li class="nav-item">
            <a href="#" class="active app-text-bold" data-toggle="tab" data-target="#tab-fillup1"><span>出発地</span></a>
          </li>
          <li class="nav-item">
            <a class="app-text-bold" href="#" data-toggle="tab" data-target="#tab-fillup2"><span>宿泊ホテル</span></a>
          </li>
          <li class="nav-item">
            <a @click="$refs.booking.onShow()" class="app-text-bold" href="#" data-toggle="tab" data-target="#tab-fillup3"><span>予約一覧</span></a>
          </li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane active" id="tab-fillup1">
            <StartPoint ref="start_points" :filters="filters"/>
          </div>
          <div class="tab-pane" id="tab-fillup2">
            <Hotel ref="hotel" :filters="filters"/>
          </div>
          <div class="tab-pane" id="tab-fillup3">
            <Booking ref="booking" :filters="filters"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const EVENT_PAGE_CHANGE = 'EVENT_PAGE_CHANGE';
const EVENT_SORT_BY = 'EVENT_SORT_BY';
const EVENT_SEARCH = 'EVENT_SEARCH';
const EVENT_PER_PAGE_CHANGE = 'EVENT_PER_PAGE_CHANGE';
import StartPoint from '@components/cs/analysis/StartPoint';
import Hotel from '@components/cs/analysis/Hotel';
import Booking from '@components/cs/analysis/Booking';

export default {
  components: {StartPoint, Hotel, Booking},
  data() {
    var filters = [
      {
        name: 'checkin_date',
        type: 'greater_or_equal',
        label: this.$t('booking.checkin_date'),
        from: true,
        boundaryClass: 'col-lg-6',
        uiType: 'datepicker_from',
      },
      {
        name: 'checkin_date',
        type: 'less_or_equal',
      },
    ];
    _.forEach(this.$route.query, (value, columnString) => {
      if (_.includes(columnString, 'filters.')) {
        let splited = columnString.split('.');
        let name = splited[1];
        let type = splited[2];
        _.forEach(filters, (filter, index) => {
          if (filter.name === name && filter.type === type) {
            filters[index] = {...filters[index], value: value}
          }
        })
      }
    })

    return {
      filters: filters
    }
  },
  watch: {
    "$route.query": {
      handler: function (after, before) {
        if (!_.isEqual(after, before)) {
          this.setSearchStateByRouteQuery()
        }
      },
      deep: true,
    }
  },
  computed: {
    hasFiltersUi() {
      var hasFiltersUi = false;
      _.forEach(this.filters, filter => {
        if (filter.uiType) {
          hasFiltersUi = true;
        }
      })
      return hasFiltersUi;
    },
  },
  methods: {
    searchHandle() {
      this.updateRouteQuery([EVENT_SEARCH])
    },
    setSearchStateByRouteQuery() {
      var newFilters = _.cloneDeep(this.filters)
      _.forEach(newFilters, (filter, index) => {
        newFilters[index] = {...filter, value: null}
      })
      _.forEach(this.$route.query, (value, columnString) => {
        if (_.includes(columnString, 'filters.')) {
          let splited = columnString.split('.');
          let name = splited[1];
          let type = splited[2];
          _.forEach(newFilters, (filter, index) => {
            if (filter.name === name && filter.type === type) {
              newFilters[index] = {...newFilters[index], value: value}
            }
          })
        }
      })
      this.filters = newFilters
    },
    resetAllSearch() {
      if (Object.keys(this.$route.query).length === 0) {
        this.setSearchStateByRouteQuery()
      } else {
        this.$router.push({name: this.$route.name, query: {}})
      }
    },
    updateRouteQuery(events) {
      if (!_.isArray(events)) {
        events = [events]
      }
      var queryObj = _.clone(this.$route.query);
      _.forEach(events, event => {
        if (event === EVENT_SEARCH) {
          const filters = this.filters;
          _.forEach(filters, (filter) => {
            if (filter.value && filter.value.toString().length) {
              queryObj['filters.' + filter.name + '.' + filter.type] = filter.value
            } else {
              delete queryObj['filters.' + filter.name + '.' + filter.type];
            }
          })
        }
      })
      if (!_.isEqual(queryObj, this.$route.query)) {
        this.$router.push({query: queryObj})
      } else {
        this.$refs.start_points.getEntries();
        this.$refs.hotel.getEntries();
        this.$refs.booking.getEntries();
      }
    },
  }
};
</script>
